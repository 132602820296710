export const geoApiOptions = {
    method: "GET",
    headers: {
      "X-RapidAPI-Key": '64ba6bed7amsh6c87737ef885863p1fc694jsn07229b6e480d',// enter your rapid api key here
      "X-RapidAPI-Host": "wft-geo-db.p.rapidapi.com",
    },
  };
  export const GEO_API_URL = "https://wft-geo-db.p.rapidapi.com/v1/geo";
  
  export const WEATHER_API_URL = "https://api.openweathermap.org/data/2.5/";
  export const WEATHER_API_KEY = "b6d7f343a2c91a6c0e25b106b5046046"; 